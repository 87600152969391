<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="32.25%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
        <div v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'park_name',
    label: '楼宇名称',
    type: 'input',
    rules: [{ required: true, message: '请输入楼宇名称!' }]
  },
  {
    name: 'park_address',
    label: '楼宇地址',
    type: 'input',
    rules: [{ required: true, message: '请输入楼宇地址!' }]
  },
  // {
  //   name: 'payment_total',
  //   label: '收款金额',
  //   type: 'number',
  //   rules: []
  // },
]
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增楼宇',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()

    if (this.item) {
      this.ModalTitle = '编辑楼宇'
      await this.getDetail(this.item.park_id);
      this.form.setFieldsValue({park_name:this.item.park_name,park_address:this.item.park_address})
      this.loading=false;
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getDetail(park_id) {
      let res = await this.$store.dispatch('marketingParkDetailAction', {data: {park_id}})
      this.loading = false
      return res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          this.confirmLoading=true
          if (item) {
              params.park_id = item.park_id
          }
          await this.$store.dispatch(item ? 'marketingParkUpdateAction':'marketingParkAddAction', { data: params })
          .then(res=>{
            form.resetFields()
            this.confirmLoading=false
            this.$message.success('操作成功~')
            this.parent.hideEditModal(1)
          }).catch(err=>{
            this.confirmLoading=false
            this.$message.error('操作失败~')
          })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({qrcode_logo:this.imageUrl})
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
        this.form.setFieldsValue({qrcode_logo:this.imageUrl})
      }
      this.imagesVisible = false
    },
  }
}
</script>
