<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">房租缴纳</a-button>
          </a-form-item>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
              <a-tooltip placement="topLeft" >
              <template slot="title">
                  <span>时间筛选</span>
              </template>
              <a-range-picker
                  :ranges="rangesData"
                  :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"
                  @change="(date, dateString)=>onChange(date, dateString,2)" style="width:230px;" />
              </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>租赁主体</span>
              </template>
              <a-select allowClear v-model="searchData.room_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选租赁主体" style="width: 210px">
                <a-select-option v-for="(d, index) of rooms" :key="index" :value="d['park_room_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属楼宇</span>
              </template>
              <a-select allowClear v-model="searchData.park_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选择楼宇" style="width: 210px">
                <a-select-option v-for="(d, index) of parks" :key="index" :value="d['park_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
              <a-radio-group :value="searchData.bill_type">
              <a-radio-button value="rent" @click="handleTypeChange('rent')">
                  房租费
              </a-radio-button>
              <a-radio-button value="property"  @click="handleTypeChange('property')">
                  物业费
              </a-radio-button>
              </a-radio-group>
          </a-form-item>

          <a-form-item>
              <a-radio-group :value="searchData.bill_confirm">
              <a-radio-button value="1" @click="handleConfChange('1')">
                  入账
              </a-radio-button>
              <a-radio-button value="0"  @click="handleConfChange('0')">
                  未入账
              </a-radio-button>
              </a-radio-group>
          </a-form-item>


          <a-form-item>
              <a-radio-group :value="searchData.bill_fapiao">
              <a-radio-button value="1" @click="handleFapiaoChange('1')">
                  已开票
              </a-radio-button>
              <a-radio-button value="0"  @click="handleFapiaoChange('0')">
                  未开票
              </a-radio-button>
              </a-radio-group>
          </a-form-item>

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="out_trade_no"
        :columns="columns" :dataSource="list" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <!-- <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template> -->

          <template slot="bill_confirm" slot-scope="text,record">
              <a-tag color="green" v-if="text==1">
                  完成入账
              </a-tag>
          </template>
          
          <template slot="images" slot-scope="text, record">
            <LImg :preview="0" v-for="(image, index) of record.bill_images" :key="index"  
                :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
          </template>

          <template slot="notice" slot-scope="text, record">
            <a href="javascript:;">通知单</a>
          </template>

          <template slot="bill_fapiao" slot-scope="text , record , index">
              <a-switch v-if="index+1 != list.length" checkedChildren="已开票" unCheckedChildren="未开票" :checked="record.bill_fapiao == '1'" @change="changeStatus(record)"/>
          </template>

          <template slot="action" slot-scope="text,record">
            <div>
              <a href="javascript:;" key="10" v-if="record.bill_confirm==0" @click="toConf(record)">入账确认</a>
              <a-divider v-if="record.bill_confirm==0" type="vertical" />
              <a href="javascript:;" key="7" v-if="record.bill_confirm==0" @click="toDel(record)">记录删除</a>
            </div>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '房号', width: '120px',  dataIndex: 'room_no', key: '17'},
  { title: '租赁主体', width: '200px',  dataIndex: 'room_name', key: '7'},
  { title: '园区名称', dataIndex: 'park_name', key: '2' ,align:'center',with:'160px',ellipsis:true,sorter:()=>{}},
  { title: '缴费日期', dataIndex: 'bill_date', key: 'bill_date' ,with:'230px',ellipsis:true,sorter:()=>{}},
  { title: '缴费类型', dataIndex: 'bill_type', key: 'bill_type' ,with:'130px',ellipsis:true,sorter:()=>{}},
  { title: '缴费金额', dataIndex: 'bill_amount', key: 'bill_amount' ,with:'160px',sorter:()=>{}},
  { title: '入账状态', dataIndex: 'bill_confirm', key: 'bill_confirm',scopedSlots: { customRender: 'bill_confirm' } ,with:'160px',sorter:()=>{}},
  { title: '开票状态', dataIndex: 'bill_fapiao', scopedSlots: { customRender: 'bill_fapiao' }, key: 'bill_fapiao' ,ellipsis:true},
  { title: '确认账户', dataIndex: 'bill_confirm_by', key: 'bill_created_by',with:'160px',sorter:()=>{}},
  // { title: '付款单',scopedSlots: { customRender: 'images' }, dataIndex: 'bill_images', key: 'images' ,ellipsis:true},
  { title: '通知单',scopedSlots: { customRender: 'notice' }, dataIndex: 'notice', key: 'notice' ,ellipsis:true},
  { title: '操作账户', dataIndex: 'bill_created_by', key: '6',align:"center"},
  { title: '操作', key: 'operation', fixed: 'right', width: 140, scopedSlots: { customRender: 'action' },align:'right'}
]
										
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import editModal from './editModal'
import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      parks: [],
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      paramsData:{
          start_date:moment().startOf('month').format('YYYY-MM-DD'),
          end_date:moment().endOf('month').format('YYYY-MM-DD'),
      },
      list: [],
      current: 1,
      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      rooms: [],
      consultants: [],
      channels: [],
      modalData: {},
      editVisible: false,
      searchData: {},
      authType:['market','qrcodepayment'],
      visible: false
    }
  },
  mixins: [ authority, tableMixins, ranges ],
  created() {
    // this.getConsultant()
    this.getFilterRoom(); 
    this.getFilterPark();
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    handleTypeChange(e){
        if(this.searchData.park_room_status == e){
          this.searchData.park_room_status = ''
        }else{
          this.searchData.park_room_status = e
        }
        this.getList()
    },
    async getFilterPark() {
      let res = await this.$store.dispatch('marketingRentFilterParkAction',{})
      this.parks = res.data;
    },

    changeStatus(record) {
      if (record.bill_fapiao == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) { 
      let that = this
      this.$confirm({
        title: `您确定取消开票？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingRentBillFapiaoAction', { data: { bill_id: item.bill_id} })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要开票？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingRentBillFapiaoAction', { data: { bill_id: item.bill_id} })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    toConf(val){
        let that = this
        this.$confirm({
            title: `您确定入帐该缴费记录？`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
            return new Promise(async (resolve, reject) => {
                let res = await that.$store.dispatch('marketingRentBillConfirmAction', { data: { bill_id: val.bill_id} })
                if (res) {
                that.getList()
                that.$message.success('操作成功~')
                resolve(res)
                }
            }).catch(error => console.log(error))
            }
        })
    },
    toDel(val){
        let that = this
        this.$confirm({
            title: `您确定删除该缴费记录？`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
            return new Promise(async (resolve, reject) => {
                let res = await that.$store.dispatch('marketingRentBillDelAction', { data: { bill_id: val.bill_id} })
                if (res) {
                that.getList()
                that.$message.success('操作成功~')
                resolve(res)
                }
            }).catch(error => console.log(error))
            }
        })
    },
    handleFapiaoChange(e){
        if(this.searchData.bill_fapiao == e){
          this.searchData.bill_fapiao = ''
        }else{
          this.searchData.bill_fapiao = e
        }
        this.getList()
    },
    
    handleTypeChange(e){
        if(this.searchData.bill_type == e){
          this.searchData.bill_type = ''
        }else{
          this.searchData.bill_type = e
        }
        this.getList()
    },
    handleConfChange(e){
        if(this.searchData.bill_confirm == e){
          this.searchData.bill_confirm = ''
        }else{
          this.searchData.bill_confirm = e
        }
        this.getList()
    },
    handleChange(pagination, filters, sorter){
      if(sorter.order){
          if(sorter.order === "ascend"){
              this.searchParams['sort'] = `${sorter.field}`
          }else{
              this.searchParams['sort'] = `-${sorter.field}`
          }
      }else{
          this.searchParams['sort'] = ''
          }
      this.getList()
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.searchData,
        }
        exportData.search.start_date = this.paramsData.start_date
        exportData.search.end_date = this.paramsData.end_date
        let res = await this.$store.dispatch('marketingRentBillExportAction', {data:exportData})
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `缴费记录.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }
        this.exportLoading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getFilterRoom() {
      let res = await this.$store.dispatch('marketingRentFilterRoomAction',{})
      this.rooms = res.data;
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel(parent_id) {
      let searchParams = {}
      if (parent_id) {
        searchParams.parent_id = parent_id
      }
      let res = await this.$store.dispatch('searchSourceChannelAction', { data: searchParams })
      if (parent_id) {
        this.channels = res.data
      } else {
        this.parentChannels = res.data
      }
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.start_date = this.paramsData.start_date
      this.searchParams.search.end_date = this.paramsData.end_date
      let res = await this.$store.dispatch('marketingRentBilllistAction', { data: this.searchParams })
      this.list = res.items

      this.list.push({
        'bill_amount':res._total.bill_amount,
      })

      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
