<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>租赁备案</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">房租缴纳<a-badge :count="0" /></span>
          <bill v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">租赁记录<a-badge :count="0" /></span>
          <room v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">楼宇管理<a-badge :count="0" /></span>
          <park v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">缴纳统计<a-badge :count="0" /></span>
          <statistics v-if="activeKey==='4'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import bill from './bill/index'
import park from './park/index'
import room from './room/index'
import statistics from './statistics/index'

export default {
  components: {
    bill,
    park,
    room,
    statistics
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

