<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="32.25%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
        <div v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group >
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'room_id',
    label: '租赁备案',
    type: 'select',
    rules: [{ required: true, message: '请选择租赁备案!' }],
    items: {
      data: 'rooms',
      label: 'filter_name',
      value: 'park_room_id'
    }
  },
  // {
  //   name: 'park_name',
  //   label: '楼宇名称',
  //   type: 'input',
  //   rules: [{ required: true, message: '请输入楼宇名称!' }]
  // },
  // {
  //   name: 'park_address',
  //   label: '楼宇地址',
  //   type: 'input',
  //   rules: [{ required: true, message: '请输入楼宇地址!' }]
  // },
  {
    name: 'bill_date',
    label: '缴费日期',
    type: 'date',
    rules: [{ required: true, message: '请输入缴费日期!' }]
  },
  {
    name: 'bill_type',
    label: '缴费类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择缴费类型!' }],
    items: {
      data: 'bill_type',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'bill_amount',
    label: '缴费合计',
    type: 'input',
    rules: [{ required: true, message: '请输入缴费合计!' }]
  },
  {
    name: 'bill_images',
    label: '缴费图片',
    type: 'upload',
    rules: [{ required: false, message: '请输入缴费图片!' }]
  },
]
import url from '@/utils/URL'
import moment from 'moment'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '房租缴纳',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        rooms:[],
        bill_type:[
          {label:'房租',value:'rent'},
          {label:'物业费',value:'property'}
        ],
      },
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    // await this.getClassCategory()
    await this.getFilterRoom();
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getFilterRoom() {
      let res = await this.$store.dispatch('marketingRentFilterRoomAction',{})
      this.seleteItems.rooms = res.data;
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          params.bill_images = this.imageUrl
          if(params.bill_date){
            params.bill_date = moment(params.bill_date).format('YYYY-MM-DD')
          }
          this.confirmLoading=true
          await this.$store.dispatch('marketingParkBillAddAction', { data: params })
          .then(res=>{
            form.resetFields()
            this.confirmLoading=false
            this.$message.success('操作成功~')
            this.parent.hideEditModal(1)
          })
          .catch(err=>{
            this.confirmLoading=false
            this.$message.error('操作失败~')
          })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({bill_images:this.imageUrl})
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
        this.form.setFieldsValue({qrcode_logo:this.imageUrl})
      }
      this.imagesVisible = false
    },
  }
}
</script>
