<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">租赁备案</a-button>
          </a-form-item>
          <!-- <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item> -->
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">

          <a-form-item>
              <a-tooltip placement="topLeft" >
              <template slot="title">
                  <span>到期时间</span>
              </template>
              <a-range-picker
                  :ranges="rangesData"
                  @change="(date, dateString)=>onChange(date, dateString,2)" style="width:230px;" />
              </a-tooltip>
          </a-form-item>
           
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>合同单位</span>
              </template>
              <a-input allowClear v-model.trim="searchData.park_room_name" placeholder="合同单位" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>房号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.park_room_no" placeholder="楼宇房号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属楼宇</span>
              </template>
              <a-select allowClear v-model="searchData.park_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选择楼宇" style="width: 210px">
                <a-select-option v-for="(d, index) of parks" :key="index" :value="d['park_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
              <a-radio-group :value="searchData.park_room_status">
              <a-radio-button value="1" @click="handleTypeChange('1')">
                  在租
              </a-radio-button>
              <a-radio-button value="0"  @click="handleTypeChange('0')">
                  退租
              </a-radio-button>
              </a-radio-group>
          </a-form-item>

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="out_trade_no"
        :columns="columns" :dataSource="list" @change="handleChange" :scroll="{ x: 1500 }">
          <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="park_room_status" slot-scope="text,record, index">
              <a-switch v-if="index+1 != list.length" checkedChildren="在租" unCheckedChildren="退租" :checked="record.park_room_status == '1'" @change="changeStatus(record)"/>
          </template>

          <template slot="park_room_chenben" slot-scope="text,record, index">
              <span v-if="record.park_room_chenben">{{record.park_room_chenben.chenben_bili}}%</span>
          </template>

          <template slot="park_room_wuye_lirun" slot-scope="text,record, index">
              <span v-if="record.park_room_chenben">{{record.park_room_chenben.wuye_lirun}}</span>
          </template>

          <template slot="park_room_lirun" slot-scope="text,record, index">
              <span v-if="record.park_room_chenben">{{record.park_room_chenben.lirun}}</span>
          </template>

          <template slot="park_room_channel_fee" slot-scope="text,record, index">
              <span v-if="record.park_room_chenben">{{record.park_room_chenben.chenben}}</span>
          </template>

          <template slot="action" slot-scope="text,record, index">
            <a-icon v-if="index+1 != list.length" key="edit" type="edit" @click="showEditModal(record)" />
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '房号', width: '110px',  dataIndex: 'park_room_no', key: 'park_room_no'},
  { title: '合同单位', dataIndex: 'park_room_name', key: 'park_room_name' ,ellipsis:true},
  { title: '合同起始', dataIndex: 'park_room_start', key: 'park_room_start' ,sorter:()=>{},ellipsis:true},
  { title: '合同截止', dataIndex: 'park_room_end', key: 'park_room_end' ,align:'center',ellipsis:true,sorter:()=>{}},
  { title: '免租期', dataIndex: 'park_room_free_day', key: 'park_room_free_day' ,ellipsis:true,sorter:()=>{}},
  { title: '面积', dataIndex: 'park_room_space', key: 'park_room_space' ,ellipsis:true,sorter:()=>{}},
  { title: '单价', dataIndex: 'park_room_per_price', key: 'park_room_per_price' ,ellipsis:true,sorter:()=>{}},
  { title: '获客成本', dataIndex: 'park_room_chenben', scopedSlots: { customRender: 'park_room_chenben' },ellipsis:true,sorter:()=>{}},
  { title: '获客费用', dataIndex: 'park_room_channel_fee', scopedSlots: { customRender: 'park_room_channel_fee' },ellipsis:true,sorter:()=>{}},
  { title: '物业利润', dataIndex: 'park_room_wuye_lirun', scopedSlots: { customRender: 'park_room_wuye_lirun' },ellipsis:true,sorter:()=>{}},
  { title: '租金利润', dataIndex: 'park_room_lirun', scopedSlots: { customRender: 'park_room_lirun' },ellipsis:true,sorter:()=>{}},
  { title: '月租金', dataIndex: 'park_room_month_price', key: 'park_room_month_price',ellipsis:true,sorter:()=>{}},
  { title: '保证金', dataIndex: 'park_room_deposit', key: 'park_room_deposit',align:"center",sorter:()=>{},ellipsis:true},
  { title: '物业费', dataIndex: 'park_room_property', key: 'park_room_property',sorter:()=>{},ellipsis:true},
  { title: '状态', dataIndex: 'park_room_status', scopedSlots: { customRender: 'park_room_status' }, key: 'park_room_status' ,ellipsis:true},
  { title: '备注', dataIndex: 'park_room_remark', key: 'park_room_remark',ellipsis:true},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' }},
]
										
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import editModal from './editModal'
import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import { range } from 'face-api.js/build/commonjs/utils'
export default {
  name: 'gjjl',

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      paramsData:{
          start_date:'',
          end_date:'',
      },
      list: [],
      current: 1,
      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      parks: [],
      consultants: [],
      channels: [],
      modalData: {},
      editVisible: false,
      searchData: {},
      authType:['market','qrcodepayment'],
      visible: false
    }
  },
  mixins: [ authority, tableMixins , ranges ],
  created() {
    this.getFilterPark();
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    handleTypeChange(e){
        if(this.searchData.park_room_status == e){
          this.searchData.park_room_status = ''
        }else{
          this.searchData.park_room_status = e
        }
        this.getList()
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.searchData,
        }
        let res = await this.$store.dispatch('marketingQrcodepaymentExportPaymentlistAction', {data:exportData})
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `收款记录.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }
        this.exportLoading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    changeStatus(record) {
      if (record.park_room_status == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) { 
      let that = this
      this.$confirm({
        title: `您确定该租户退租"${item.park_room_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingRentRoomDelAction', { data: { park_room_id: item.park_room_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复该租户"${item.park_room_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('marketingRentRoomRecoveryAction', { data: { park_room_id: item.park_room_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleChange(pagination, filters, sorter){
      if(sorter.order){
          if(sorter.order === "ascend"){
              this.searchParams['sort'] = `${sorter.field}`
          }else{
              this.searchParams['sort'] = `-${sorter.field}`
          }
      }else{
          this.searchParams['sort'] = ''
          }
      this.getList()
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getFilterPark() {
      let res = await this.$store.dispatch('marketingRentFilterParkAction',{})
      this.parks = res.data;
    },

    async getSourceChannel(parent_id) {
      let searchParams = {}
      if (parent_id) {
        searchParams.parent_id = parent_id
      }
      let res = await this.$store.dispatch('searchSourceChannelAction', { data: searchParams })
      if (parent_id) {
        this.channels = res.data
      } else {
        this.parentChannels = res.data
      }
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.start_date = this.paramsData.start_date
      this.searchParams.search.end_date = this.paramsData.end_date
      let res = await this.$store.dispatch('marketingRentRoomlistAction', { data: this.searchParams })
      this.list = res.items

      this.list.push({
        'park_room_month_price':res._total.park_room_month_price,
        'park_room_deposit':res._total.park_room_deposit ,
        'park_room_property':res._total.park_room_property ,
      })

      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
