<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="32.25%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
        <div v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <!-- <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/> -->
        <a-date-picker v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" >
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'park_room_no',
    label: '楼宇房号',
    type: 'input',
    rules: [{ required: true, message: '请输入楼宇房号!' }]
  },
  {
    name: 'park_room_name',
    label: '合同单位',
    type: 'input',
    rules: [{ required: true, message: '请输入合同单位!' }]
  },
  {
    name: 'park_id',
    label: '所在楼宇',
    type: 'select',
    rules: [{ required: true, message: '请选择楼宇!' }],
    items: {
      data: 'parks',
      label: 'filter_name',
      value: 'park_id'
    }
  },
  {
    name: 'park_room_start',
    label: '开始日期',
    type: 'date',
    rules: [{ required: true, message: '请输入开始日期!' }]
  },
  {
    name: 'park_room_end',
    label: '截止日期',
    type: 'date',
    rules: [{ required: true, message: '请输入截止日期!' }]
  },
  {
    name: 'park_room_free_day',
    label: '免租日期',
    type: 'input',
    rules: [{ required: true, message: '请输入免租日期!' }]
  },
  {
    name: 'park_room_space',
    label: '租赁面积',
    type: 'input',
    rules: [{ required: true, message: '请输入租赁面积!' }]
  },
  {
    name: 'park_room_per_price',
    label: '租赁单价',
    type: 'input',
    rules: [{ required: true, message: '请输入租赁单价!' }]
  },
  {
    name: 'park_room_month_price',
    label: '月租金',
    type: 'input',
    rules: [{ required: true, message: '请输入月租金!' }]
  },
  {
    name: 'park_room_deposit',
    label: '保证金',
    type: 'input',
    rules: [{ required: true, message: '请输入保证金!' }]
  },
  {
    name: 'park_room_property',
    label: '物业费',
    type: 'input',
    rules: [{ required: true, message: '请输入物业费!' }]
  },
  {
    name: 'park_room_remark',
    label: '租赁备注',
    type: 'textarea',
    rules: [{ required: false, message: '请输入租赁备注!' }]
  },
]
import url from '@/utils/URL'
import moment from 'moment'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增备案',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        parks:[]
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      parks: [],
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getFilterPark()
    await this.getClassCategory()

    if (this.item) {
      this.ModalTitle = '编辑备案'
      await this.getDetail(this.item.park_room_id);
      this.form.setFieldsValue({
        park_id:this.item.park_id,
        park_room_name:this.item.park_room_name,
        park_room_no:this.item.park_room_no,
        park_room_start:this.item.park_room_start,
        park_room_end:this.item.park_room_end,
        park_room_range_month:this.item.park_room_range_month,
        park_room_free_day:this.item.park_room_free_day,
        park_room_space:this.item.park_room_space,
        park_room_per_price:this.item.park_room_per_price,
        park_room_month_price:this.item.park_room_month_price,
        park_room_deposit:this.item.park_room_deposit,
        park_room_property:this.item.park_room_property,
        park_room_remark:this.item.park_room_remark
      })
      this.loading=false;
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },

    async getFilterPark() {
      let res = await this.$store.dispatch('marketingRentFilterParkAction',{})
      this.seleteItems.parks = res.data;
    },
    async getDetail(park_room_id) {
      let res = await this.$store.dispatch('marketingRoomDetailAction', {data: {park_room_id}})
      this.loading = false
      return res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          this.confirmLoading=true
          if(params.park_room_start){
            params.park_room_start = moment(params.park_room_start).format('YYYY-MM-DD')
          }
          if(params.park_room_end){
            params.park_room_end = moment(params.park_room_end).format('YYYY-MM-DD')
          }
          if (item) {
              params.park_room_id = item.park_room_id
          }
          await this.$store.dispatch(item ? 'marketingParkRoomUpdateAction':'marketingParkRoomAddAction', { data: params })
          .then(res=>{
            form.resetFields()
            this.confirmLoading=false
            this.$message.success('操作成功~')
            this.parent.hideEditModal(1)
          })
          .catch(err=>{
            this.confirmLoading=false
            this.$message.error('操作失败~')
          })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
  }
}
</script>
