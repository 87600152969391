<template>
    <div>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <!-- <a-radio-button value="quarter">
                            季度
                        </a-radio-button> -->
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-radio-group :value="paramsData.bill_type">
                        <a-radio-button value="rent" @click="handleTypeChange('rent')">
                            房租费
                        </a-radio-button>
                        <a-radio-button value="property" @click="handleTypeChange('property')">
                            物业费
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属楼宇</span>
                        </template>
                        <a-select allowClear v-model="paramsData.park_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch :filterOption="filterOption" placeholder="请选择楼宇" style="width: 210px">
                            <a-select-option v-for="(d, index) of parks" :key="index" :value="d['park_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>

                    <!-- <a-form-item>
                        <a-cascader  v-model='department_id' @change="handleCascader" :field-names='fieldNames' placeholder="请选择部门" :options="DepOptions" change-on-select >
                            <template slot="displayRender" slot-scope="{ labels }">
                                <span>{{labels[labels.length - 1]}}</span>
                            </template>
                        </a-cascader>
                    </a-form-item>
                    
                    <a-form-item>
                        <a-select mode="multiple" style="width: 200px" v-model='paramsData.from' allowClear showSearch @change="handleUserChange" placeholder="请选择员工" :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['userid']">{{ d['name'] }}</a-select-option>
                        </a-select>
                    </a-form-item> -->
                    
                    
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-top:15px">
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <!-- <VElines :loading='loading' :title="`私信&群跳会话消息统计（条）`"  :item='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> -->
                        <wxworkanalysis :loading='loading' :title="`缴费统计`"  :item='rent_data.rent' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <!-- <a-col :lg="24" :xl="24">
                        <heatmap :loading="loadingH" :item="heatmap_data" title="会话消息时间热点图" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col> -->
                        
                    <a-col :lg="24" :xl="24">
                        <a-card size="small" title="租赁缴费统计">
                        <!-- <a-button slot="extra" @click="toExport" size="small" icon="export" :loading="exportLoading">导出</a-button> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                            <a-table size="small" :pagination="false" :bordered='false' rowKey="qywx_id"
                            :columns="col_single_msg_columns" :dataSource="col_single_msg" :scroll="{ x: RelativeWidth, y:500}">
                            </a-table>
                        </a-card>
                    </a-col>

                    <!-- <a-col :lg="24" :xl="24">
                        <LRanking title="员工私信消息接收统计（条）" :heightNum="500" :rank_data="private_chat" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col> -->
                    <!-- <a-col :lg="24" :xl="24">
                        <LRanking title="关键词统计（条）" :heightNum="500" :rank_data="keywords_chat" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col> -->
                    <!-- <a-col :lg="24" :xl="12">
                        <LRanking title="私信会话消息发送统计（条）" :heightNum="500" :rank_data="single_chat" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="群聊会话消息发送统计（条）" :heightNum="500" :rank_data="group_chat" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="员工添加客户数累计（人）" :subtitle="`累计至 ${paramsData.end_date}`" :heightNum="500" :rank_data="client_count" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="员工消息发送数累计（条）" :subtitle="`累计至 ${paramsData.end_date}`" :heightNum="500" :rank_data="client_msg_count" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col> -->
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    import { delay } from '@/utils/timeFormat'
    import wxworkanalysis from './wxworkanalysis.vue'
    import heatmap from './heatmap'
    export default {
    components: { wxworkanalysis,heatmap },
        name:'statistics',
        data() {
            return {
                loading:false,
                loadingH:false,
                exportLoading:false,
                visible:false,
                leads_data:{},
                single_chat:{},
                col_single_msg:[],
                heatmap_data:{},
                group_chat:{},
                rent_data:{
                    'rent':{title:''}
                },
                parks: [],
                keywords_chat:{},
                private_chat:{},
                client_count:{},
                col_single_msg_columns:[],
                client_msg_count:{},
                paramsData:{
                    start_date:moment().startOf('quarter').format('YYYY-MM-DD'),
                    end_date:moment().endOf('quarter').format('YYYY-MM-DD'),
                    filter_type:'month',
                    bill_type:'',
                },
                studios:[],
                DepOptions:[],
                cascaderValue:[],
                fieldNames:{
                    label:'department_name',
                    value:'department_id',
                    children:'children',
                    type:0
                },
                department_id:[],
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        mixins:[ ranges ],
        created () {
            this.toScreen()
            this.getStaff()
            this.getDepartment()
            this.getFilterPark();
        },
        computed: {
            RelativeWidth() {
                let width = 850
                if(this.filedLength >10){
                width = 900 + ( this.filedLength - 10 ) * 130
                }
                return width
            }
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.indexOf(input) >= 0;
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(obj) {
                this.getHeatmap(obj)
                this.getAnalysis(obj)

                // await this.$store.dispatch('wxworkMsgAnalysisAction', {data:obj})
                // .then(res=>{
                //     this.leads_data = res.data.chat
                //     this.single_chat = res.data.single_chat
                //     this.group_chat = res.data.group_chat
                //     // this.keywords_chat = res.data.keywords_chat
                //     // this.col_single_msg=res.data.col_single_msg
                //     this.private_chat = res.data.private_chat
                //     this.client_count = res.data.client_count
                //     this.client_msg_count = res.data.client_msg_count

                //     let single_col_date = [
                //         { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                //         { title: '员工名称', width:'160px', dataIndex: 'qywx_name',  key: 'qywx_name', fixed: 'left',ellipsis:true},
                //         { title: '合计', width:'120px', dataIndex: 'qywx_send_total', key: 'qywx_send_total',fixed: 'left',align:'right'},
                //     ]

                //     // console.log(res.data.col_single_msg.col_date);
                //     if(res.data.col_single_msg){
                //         res.data.col_single_msg.col_date.map(item=>{
                //             let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
                //             single_col_date.push(obj)
                //         })
                //     }
                //     // console.log(single_col_date);
                //     // return false
                //     // this.col_single_msg_columns=single_col_date;
                //     // this.col_single_msg = res.data.col_single_msg.rows;
                //     // console.log(single_col_date);
                //     // return false;
                //     this.loading = false
                // })
                // .catch(err=>{
                //     this.loading = false
                // })
            },
            async getFilterPark() {
                let res = await this.$store.dispatch('marketingRentFilterParkAction',{})
                this.parks = res.data;
            },
            async getAnalysis(obj){
                this.loading = true
                let res = await this.$store.dispatch('marketingRentBillAnalysisAction', {data:obj})
                this.rent_data = res.data

                let single_col_date = [
                    { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index',},
                    { title: '租赁名称', width:'230px', dataIndex: 'qywx_name',  key: 'qywx_name', ellipsis:true},
                    { title: '合计', width:'120px', dataIndex: 'qywx_send_total', key: 'qywx_send_total',align:'right'},
                ]

                // console.log(res.data.col_single_msg.col_date);
                if(res.data.col_rent){
                    res.data.col_rent.col_date.map(item=>{
                        let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
                        single_col_date.push(obj)
                    })
                }
                // console.log(single_col_date);
                // return false
                this.col_single_msg_columns=single_col_date;
                this.col_single_msg = res.data.col_rent.rows;

                this.loading = false
            },
            async getHeatmap(obj){
                this.loadingH = true
                let res = await this.$store.dispatch('wxworkMsgHeatmapAction', {data:obj})
                this.heatmap_data = res.data
                this.loadingH = false
            },
            async getStaff(val){
                let obj = {name:val}
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {data:obj})
                this.studios = res.items
            },
            async getDepartment(){
                let res = await this.$store.dispatch('wxworkStaffDepartAction', {})
                this.DepOptions = res.data.depart_list
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            handleTypeChange(e){
                if(this.searchData.bill_type == e){
                this.searchData.bill_type = ''
                }else{
                this.searchData.bill_type = e
                }
                this.toScreen()
            },
            handleUserChange(){
                this.toScreen()
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleCascader(val){
                this.cascaderValue = val
                if(val.length > 0){
                    this.paramsData.department_id = val[val.length - 1]
                }else{
                    this.paramsData.department_id = undefined
                }
                this.toScreen()
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.paramsData
                }
                let res = await this.$store.dispatch('wxworkMsgAnalysisExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `员工私信消息发送统计.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }

                this.exportLoading = false
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.loading = true
                delay(() => {
                    this.getList(obj)
                }, 200)
            },
            screenHeatmap(e){
                this.paramsData.type = e
                let obj = {
                    search:this.paramsData
                }
                this.getHeatmap(obj)
                this.getAnalysis(obj)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>